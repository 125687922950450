import React from "react"
import * as Icon from "react-feather"
import { StaticImage } from "gatsby-plugin-image"

const AboutUsContent = () => {
  return (
    <div className="repair-about-area ptb-80 bg-f7fafd">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="why-choose-us-image" >
              <StaticImage
                src="../../assets/images/career-why-join-atyeti.png"
                alt="Why join Atyeti?"
              />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="repair-about-content">
              <h2>Why join Atyeti?</h2>
              <ul>
                <li>
                  <span>
                    <Icon.Square />
                    Compensation
                  </span>
                </li>
                <li>
                  <span>
                    <Icon.Square /> Life and disability
                  </span>
                </li>
                <li>
                  <span>
                    <Icon.Square />
                    Paid time off
                  </span>
                </li>
                <li>
                  <span>
                    <Icon.Square />
                    Medical insurance{" "}
                  </span>
                </li>
                <li>
                  <span>
                    <Icon.Square /> Retirement plans
                  </span>
                </li>
                <li>
                  <span>
                    <Icon.Square />
                    Flexible work-life balance
                  </span>
                </li>
                <li>
                  <span>
                    <Icon.Square />
                    Relocation options
                  </span>
                </li>
                <li>
                  <span>
                    <Icon.Square /> Ongoing training programs
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AboutUsContent
