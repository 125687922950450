import React, { useEffect } from "react"
import Layout from "../components/_App/layout"
import Seo from "../components/_App/seo"
import Navbar from "../components/_App/Navbar"
import Footer from "../components/_App/Footer"
import WhyJoinAtyeti from "../components/Careers/WhyJoinAtyeti"
import { StaticImage } from "gatsby-plugin-image"

const Career = () => {
  // useEffect(() => {
  //   const parentEl = document.getElementById("table_career")
  //   const script = document.createElement("script")
  //   script.setAttribute("type", "text/javascript")
  //   script.setAttribute(
  //     "src",
  //     "https://static.smartrecruiters.com/job-widget/1.6.1/script/smart_widget.js"
  //   )
  //   parentEl.appendChild(script)

  //   script.onload = () => {
  //     window.widget({
  //       company_code: "AtyetiInc",
  //       bg_color_widget: "#ffffff",
  //       bg_color_headers: "#969696",
  //       bg_color_links: "#f6567",
  //       txt_color_headers: "#292929",
  //       txt_color_job: "#3d3d3d",
  //       bg_color_even_row: "#e0e0e0",
  //       bg_color_odd_row: "#f7f7f7",
  //       auto_width: "auto",
  //       auto_height: "auto",
  //       number: "on",
  //       job_title: "true",
  //       location: "true",
  //       type_of_employment: "true",
  //       published_since: "true",
  //       dateFormat: "us",
  //       add_search: "true",
  //       departments_field_id: "",
  //       jobAdType: "PUBLIC",
  //       trid: "",
  //       api_url: "https://www.smartrecruiters.com",
  //       custom_css_url:
  //         "https://static.smartrecruiters.com/job-widget/1.6.1/css/smart_widget.css",
  //     })
  //   }
  //   return () => {
  //     window.widget = null
  //   }
  // })

  return (
    <Layout>
      <Seo
        title="Careers "
        description="Are you ready for the next step in your career? Atyeti is committed to finding the best opportunity for everyone. Your next job can be anywhere in the world- that's so exciting!!!!"
        keyword="careers, career opportunity, jobs, job openings, open positions, compensation, medical insurance, flexible work-life balance, training"
      />
      <Navbar />

      <div className="agency-main-banner">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12">
              <div className="agency-banner-content">
                <h3>Career Opportunities</h3>
                <p>
                  We are always looking for the best and brightest individuals.
                </p>
                <p>
                  We're committed to finding the best opportunity for everyone.
                  Your next customer project can be anywhere in the world-
                  that's so exciting!!!!
                </p>
              </div>
            </div>

            <div className="col-lg-6 col-md-12">
              <div className="agency-banner-image">
                <StaticImage
                  src="../assets/images/career.png"
                  alt="Career Opportunities"
                  loading="eager"
                />
                <p></p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="repair-cta-area bg-003c64">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-sm-12">
              <div className="cta-repair-content">
                <h2>ARE YOU READY FOR THE NEXT STEP IN YOUR CAREER?</h2>
                <p style={{ paddingTop: "15px" }}>
                  Atyeti allows you to work in a fast growing exciting company,
                  while being able to work with industry leading Fortune 500
                  companies doing a lot of digital transformation work.
                </p>
              </div>
            </div>

            {/* <div className="col-lg-6">
              <div className="cta-repair-img">
                <StaticImage
                  src="../assets/images/man-and-women.png"
                  alt="ARE YOU READY FOR THE NEXT STEP IN YOUR CAREER?"
                />
              </div>
            </div> */}
          </div>
        </div>
      </div>

      <WhyJoinAtyeti pt-50 />

      {/* <div className="container ptb-80" style={{ overflowX: "auto" }}>
        
        <div className="row">
          <div className="col">
            <h2 style={{ paddingBottom: "10px" }}>Job Openings</h2>
            <div id="table_career">
              <script type="text/javascript" className="job_widget"></script>
            </div>
          </div>
        </div>
      </div> */}
      <Footer />
    </Layout>
  )
}
export default Career
